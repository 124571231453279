@font-face {
  font-family: "OpenSans-bold";
  src: url("../fonts/opensans-bold-webfont.woff2") format("woff2"),
    url("../fonts/opensans-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-light";
  src: url("../fonts/opensans-light-webfont.woff2") format("woff2"),
    url("../fonts/opensans-light-webfont.woff") format("woff");
  /*noinspection CssInvalidPropertyValue*/
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-italic";
  src: url("../fonts/opensans-italic-webfont.woff2") format("woff2"),
    url("../fonts/opensans-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans-regular-webfont.woff2") format("woff2"),
    url("../fonts/opensans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
