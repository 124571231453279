.dashboard-filter-container {
    .overview {
        &:hover {
            cursor: pointer;
        }
    }

    .dashboard-filter-dropdown {
        background: white;
        padding: 20px;
        border: 1px solid #e8e8e8;
        z-index: 1;
        position: relative;
        top: 5px;
        float: right;
        width: 258px;
    }
}

.dark {
    .dashboard-filter-dropdown {
        background-color: $muc-dark-background;
    }
}
