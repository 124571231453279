.ant-timeline {
    &-item {
        &-label {
            position: absolute;
            left: -85px;
        }

        &-head {
            border-radius: 50% !important;
            padding: 5px !important;

            .timeline-icon {
                svg {
                    height: 30px;
                    width: 30px;
                }
            }
        }

        &-content {
            .card {
                margin-left: 10px;
            }
        }
    }
}
