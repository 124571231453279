.sidebar-pagination {
    text-align: center;

    & .ant-pagination-simple-pager {
        input {
            color: black;
        }
    }

    & .ant-pagination-next {
        & .ant-pagination-item-link {
            margin: 0 !important;
            padding: 0 !important;
            text-align: center !important;
            //noinspection CssInvalidPropertyValue
            &:hover,
            &:focus {
                border: none !important;
                background-color: none !important;
                box-shadow: none !important;
            }
        }
    }

    & .ant-pagination-prev {
        & .ant-pagination-item-link {
            margin: 0 !important;
            padding: 0 !important;
            text-align: center !important;
            //noinspection CssInvalidPropertyValue
            &:hover,
            &:focus {
                border: none !important;
                background-color: none !important;
                box-shadow: none !important;
            }
        }
    }
}
