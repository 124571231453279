// TODO: Make this a real selector
.ant-select-multiple .ant-select-selection-item-content {
    color: black !important;
}

body {
    font-family: "OpenSans", sans-serif;
    position: absolute;
    // text-transform: capitalize;
    top: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    button {
        text-transform: capitalize;
        cursor: pointer;
    }

    .bold {
        font-family: "OpenSans-bold", sans-serif;
        font-weight: bold;
    }
    .light {
        font-family: "OpenSans-light", sans-serif;
        font-weight: lighter;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label,
    b,
    strong,
    .badge,
    .console-side-nav-result-data-badge {
        @extend .bold;
    }

    a {
        color: $muc-primary;
        &.disabled {
            color: gray;
        }
    }

    label {
        text-transform: capitalize;
        color: var(--muc-light-font-dark);
    }

    .text-right {
        text-align: right;
    }

    .ant-tag {
        border-radius: 100px;
        padding-right: 10px;
        padding-left: 10px;
        font-weight: 700;
    }

    hr {
        border: none;
        border-bottom: 1px solid transparent;
    }

    svg {
        .cls-1 {
            fill: $muc-brand-grey;
        }

        .cls-2 {
            fill: $muc-brand-dark-grey;
        }

        .cls-3 {
            fill: $muc-brand-light-grey;
        }

        .cls-4 {
            fill: $muc-brand-primary-color;
        }

        .cls-5 {
            fill: $muc-brand-dark-grey;
        }

        .cls-6 {
            fill: $muc-brand-dark-grey;
        }
    }

    .ant-btn.ant-btn-link.delete-button {
        color: $muc-danger;
    }

    .ant-btn-loading span:not(.ant-btn-loading-icon):after {
        content: "...";
    }

    .anticon-loading.anticon-spin:after {
        content: "" !important;
    }

    .ant-btn.ant-dropdown-trigger {
        padding: 1px 15px;
        font-size: 11px;
    }

    .ant-form {
        .ant-form-item-has-error {
            .ant-input,
            .ant-input-affix-wrapper {
                border-color: $muc-danger !important;

                &:hover {
                    border-color: $muc-danger !important;
                }
            }

            .ant-input:focus,
            .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
                border-color: $muc-danger !important;
                box-shadow: 0 0 0 2px $muc-danger-glow !important;
            }

            .ant-input-affix-wrapper.ant-input-affix-wrapper-focused input {
                box-shadow: none !important;
            }
        }

        .ant-form-item-explain.ant-form-item-explain-error [role="alert"] {
            color: $muc-danger !important;
            display: block;
            text-transform: none;
        }
        .ant-input-borderless,
        .ant-input-borderless:hover,
        .ant-input-borderless:focus,
        .ant-input-borderless-focused,
        .ant-input-borderless-disabled,
        .ant-input-borderless[disabled],
        .ant-select.ant-select-borderless.ant-select-disabled {
            border: 1px solid transparent;
            &::placeholder,
            .ant-select-selection-placeholder {
                display: none;
            }
        }
        .ant-form-item-control-input-content {
            text-transform: capitalize;
        }
    }

    .login-agreement-button {
        position: relative;
        right: 0;
        margin: 5px;
    }

    .left-label {
        &:after {
            content: ":";
            padding-right: 0.5em;
        }
    }

    .no-capitalization {
        text-transform: none;
    }
    .ant-dropdown-menu {
        border: 1px solid transparent;
    }
    .ant-select-dropdown {
        border: 1px solid transparent;
    }

    .ant-table {
        background: none;
        .ant-table-container table > thead > tr:first-child th:first-child,
        .ant-table-container table > thead > tr:first-child th:last-child {
            border-radius: 0;
        }
        &-container {
            overflow-x: auto;
        }
        thead tr th {
            @extend .bold;
            background: none;
            font-size: 11px;
            padding: 2px 4px;
            border-bottom-width: 2px;
            white-space: nowrap;
        }
        tbody tr td {
            padding: 2px 4px;
            font-size: 11px;
        }
    }

    .p-0 {
        padding: 0 !important;
    }
    .p-1 {
        padding: 0.25rem !important;
    }
    .p-2 {
        padding: 0.5rem !important;
    }
    .p-3 {
        padding: 1rem !important;
    }
    .p-4 {
        padding: 1.5rem !important;
    }
    .p-5 {
        padding: 3rem !important;
    }
    .px-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-0 {
        padding-top: 0 !important;
    }
    .pt-1 {
        padding-top: 0.25rem !important;
    }
    .pt-2 {
        padding-top: 0.5rem !important;
    }
    .pt-3 {
        padding-top: 1rem !important;
    }
    .pt-4 {
        padding-top: 1.5rem !important;
    }
    .pt-5 {
        padding-top: 3rem !important;
    }
    .pe-0,
    .pr-0 {
        padding-right: 0 !important;
    }
    .pe-1,
    .pr-1 {
        padding-right: 0.25rem !important;
    }
    .pe-2,
    .pr-2 {
        padding-right: 0.5rem !important;
    }
    .pe-3,
    .pr-3 {
        padding-right: 1rem !important;
    }
    .pe-4,
    .pr-4 {
        padding-right: 1.5rem !important;
    }
    .pe-5,
    .pr-5 {
        padding-right: 3rem !important;
    }
    .pb-0 {
        padding-bottom: 0 !important;
    }
    .pb-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-3 {
        padding-bottom: 1rem !important;
    }
    .pb-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-5 {
        padding-bottom: 3rem !important;
    }
    .ps-0,
    .pl-0 {
        padding-left: 0 !important;
    }
    .ps-1,
    .pl-1 {
        padding-left: 0.25rem !important;
    }
    .ps-2,
    .pl-2 {
        padding-left: 0.5rem !important;
    }
    .ps-3,
    .pl-3 {
        padding-left: 1rem !important;
    }
    .ps-4,
    .pl-4 {
        padding-left: 1.5rem !important;
    }
    .ps-5,
    .pl-5 {
        padding-left: 3rem !important;
    }

    .m-0 {
        margin: 0 !important;
    }
    .m-1 {
        margin: 0.25rem !important;
    }
    .m-2 {
        margin: 0.5rem !important;
    }
    .m-3 {
        margin: 1rem !important;
    }
    .m-4 {
        margin: 1.5rem !important;
    }
    .m-5 {
        margin: 3rem !important;
    }
    .mx-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .my-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .mt-0 {
        margin-top: 0 !important;
    }
    .mt-1 {
        margin-top: 0.25rem !important;
    }
    .mt-2 {
        margin-top: 0.5rem !important;
    }
    .mt-3 {
        margin-top: 1rem !important;
    }
    .mt-4 {
        margin-top: 1.5rem !important;
    }
    .mt-5 {
        margin-top: 3rem !important;
    }
    .me-0,
    .mr-0 {
        margin-right: 0 !important;
    }
    .me-1,
    .mr-1 {
        margin-right: 0.25rem !important;
    }
    .me-2,
    .mr-2 {
        margin-right: 0.5rem !important;
    }
    .me-3,
    .mr-3 {
        margin-right: 1rem !important;
    }
    .me-4,
    .mr-4 {
        margin-right: 1.5rem !important;
    }
    .me-5,
    .mr-5 {
        margin-right: 3rem !important;
    }
    .mb-0 {
        margin-bottom: 0 !important;
    }
    .mb-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-3 {
        margin-bottom: 1rem !important;
    }
    .mb-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-5 {
        margin-bottom: 3rem !important;
    }
    .ms-0,
    .ml-0 {
        margin-left: 0 !important;
    }
    .ms-1,
    .ml-1 {
        margin-left: 0.25rem !important;
    }
    .ms-2,
    .ml-2 {
        margin-left: 0.5rem !important;
    }
    .ms-3,
    .ml-3 {
        margin-left: 1rem !important;
    }
    .ms-4,
    .ml-4 {
        margin-left: 1.5rem !important;
    }
    .ms-5,
    .ml-5 {
        margin-left: 3rem !important;
    }
    .full-wh {
        height: 100%;
        width: 100%;
    }
    .right_align_column{
        justify-content: right;
    }
}
