.actions {
  text-align: right;
  width: 100%;
  .table-action-links {
    a {
      background: initial;
      padding-right: 15px;
      span {
        padding-right: 7px;
      }
    }
  }
  .actions-alternate-buttons {
    display: inline-block;
    padding-right: 15px;
    a, button {
      color: rgba(251,176,64,0.70);
    }
  }
}

.ant-table-filter-column {
  padding-right: 10px;
}

.antd-table-title-cell {
  display: flex;
  justify-content: space-between;

  .anticon {
    padding-top: 4px;
  }
}

span.ant-table-column-sorter.ant-table-column-sorter-full {
  display: none;
}

.dark {
  .actions {
    .actions-alternate-buttons {
      border-right: 1px solid #2e4a64;
    }
  }

  /// the following selector selects the separator between header columns
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #2e4a64;
  }

  th.ant-table-cell {
    &:hover {
      background: #233c53;
    }
  }
}

.light {
  .actions {
    .actions-alternate-buttons {
      border-right: 1px solid #e8e8e8;
    }
  }

  th.ant-table-cell {
    &:hover {
      background: #f8f8f8;
    }
  }
}