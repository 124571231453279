.dark {
  .ant-upload{
    border: 1px dashed #d9d9d9 !important;
    &:hover{
      border-color: blue !important;
    }
  }
}

.ant-upload.ant-upload-drag {
  height: 78% !important;

  .anticon-upload {
    font-size: 30px !important;
  }
  .ant-upload-text{
    padding: 5px;
  }
}