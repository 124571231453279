.ant-modal {
  &.muc-modal-caution.muc-modal-with-header {
    .ant-modal-body {
      #login-failed-modal-body {
        text-align: center;
        text-transform: none;
      }
    }

    .ant-modal-footer,
    .ant-modal-header {
      background-color: $muc-warning-light;
    }

    .ant-modal-close {
      &:hover {
        color: $muc-warning;
      }
    }
  }
}
