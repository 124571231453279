.ant-select-tree,
.permission-select {
  text-transform: capitalize;

  .permission-action {
    align-content: space-between;

    span[role="img"] {
      padding-right: 0.5em;
    }

    &.edit-permission {
      color: $muc-warning;
    }

    &.delete-permission {
      color: $muc-danger;
    }
  }
}

// .ant-select-selection-item-content {
//   color: black;
// }

//.ant-select-tree {
//  .permission-action {
//    &.edit-permission {
//      color: $muc-warning;
//    }
//
//    &.delete-permission {
//      color: $muc-danger;
//    }
//  }
//}

//.permission-select {
//  //.ant-select-selection-item .ant-select-selection-item-content .permission-action {
//  //  //background-color: #85c75c;
//  //
//  //  :has(.edit-permission) {
//  //    background-color: $muc-warning;
//  //  }
//  //}
//  .ant-select-selection-item-content > .permission-action {
//    &.edit-permission {
//      background-color: $muc-warning;
//    }
//
//    :has(&.delete-permission) {
//      background-color: $muc-danger;
//    }
//  }
//}
