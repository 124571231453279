span.warning-chip {
    height: 20px;
    position: absolute;
    padding-right: 24px;
    padding-top: 2px;
    right: 0;
    text-align: right;
    top: 0;
    width: 100%;
     z-index: 5;
}

.ant-table-cell {
    span.warning-chip {
        position: relative;
        right: initial;
        top: initial;
        width: initial;
    }
}