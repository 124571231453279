#console {
    #navigation-header {
        border-bottom: 1px solid transparent;
        display: flex !important;
        flex-direction: row;
        line-height: 1.3;
        align-items: stretch;

        #nav-logo-collection {
            padding: 5px;
            width: $nav-logo-collection-width;

            display: flex;
            align-items: center;
            justify-content: center;

            .navigation-logo {
                text-align: center;

                width: 60%;

                svg,
                object {
                    max-height: 50px;
                    max-width: 190px;
                }

                .anticon {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        #header-nav-action-container {
            flex-grow: 1;
            display: flex;
            align-items: stretch;
            position: relative;
            padding-left: 50px;
        }

        #header-search {
            position: absolute;
            min-width: 50px;
            top: 0;
            left: 0;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;
            transition: width 0.5s ease;
            will-change: width;

            .search-trigger {
                display: inline-block;
            }

            input {
                width: calc(100% - 50px);
                margin-left: 5px;
                background: none;
                border: none;

                &:focus {
                    outline: none;
                }
            }

            .anticon-close {
                opacity: 0;
            }

            .search-icon {
                position: absolute;
                font-size: 1.25rem;
                margin: 20px 0 0 15px;
                padding-bottom: 5px;
            }

            &.open {
                width: 100%;

                .search-icon {
                    border-bottom: 1px solid transparent;
                    width: calc(100% - 30px);

                    .anticon-close {
                        opacity: 1;
                    }
                }
            }
        }

        #navigation-buttons {
            display: flex;
            flex-grow: 1;
            margin-left: 3px;

            #nav-links {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-direction: row;
                align-items: stretch;

                li {
                    display: flex;
                    align-items: stretch;
                    text-align: center;

                    a {
                        border: none;
                        background: none;
                        border-radius: 0;
                        padding: 0 15px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:hover,
                        &.current {
                            box-shadow: inset 0 -5px 0 $muc-brand-primary-color;
                            background-color: rgb(35, 60, 83);
                        }
                    }
                }
            }
        }

        #navigation-dropdown {
            align-items: center;
            border-left: 1px solid transparent;
            cursor: pointer;
            display: flex;
            margin-left: auto;
            min-width: 250px;
            position: relative;
            text-transform: capitalize;
            white-space: nowrap;

            .selected-company {
                padding: 0 15px;
                width: 100%;
                display: flex;
                align-items: center;

                .company-name {
                    display: inline-block;
                    max-width: 210px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    @extend .bold;
                    // padding: 5px 0;
                }

                .anticon-caret-down {
                    margin-left: auto;
                    padding-left: 20px;
                    // padding: 5px 0;
                }
            }

            &-company-list {
                position: absolute;
                height: 100vh;
                width: 100%;
                border-left: 1px solid transparent;
                border-top: 1px solid transparent;
                left: -1px;
                top: 100%;
                bottom: 0;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                white-space: normal;
                display: none;
                padding-bottom: 200px;

                button {
                    border: none;
                    border-radius: 0;
                    text-align: left;
                    padding: 15px;
                    width: 100%;
                    background: none;

                    &.selected,
                    &:hover,
                    &:focus {
                        box-shadow: inset 5px 0 0 $muc-brand-primary-color;
                        outline: none;
                    }
                }

                .location-filter {
                    padding: 15px;
                    border-bottom: 1px solid transparent;
                    border-top: 3px solid transparent;

                    &-inner {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .anticon {
                            flex-grow: 0;
                            flex-shrink: 1;
                        }

                        input {
                            background: none;
                            border: none;
                            flex-grow: 1;
                            margin-left: 5px;

                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }
            }

            &.open {
                #navigation-dropdown-company-list {
                    display: flex;
                    z-index: 20;
                    flex-direction: column;
                    height: calc(100vh - 65px);
                }
            }
        }

        #alert-panel, #settings-panel {
            align-items: center;
            border-left: 1px solid transparent;
            display: flex;
            margin-left: auto;
            min-width: 50px;
            position: relative;
            text-transform: capitalize;
            white-space: nowrap;

            .anticon {
                font-size: 25px;
                cursor: pointer;
            }
        }
    }
}
